<template>
  <div class="container-card-input">
    <div class="add-item">
      <h3>Cantidad de Baños</h3>
      <el-input-number
        v-model="num"
        @change="handleAdd(num)"
        controls-position="right"
        :min="minNum"
        :max="10"
      />
    </div>
    <div class='cards'>
      <div class="card-general" v-for="(bathroom, idx) in bathroom" :key="idx">
        <div class="card-title">
          <strong>Baño</strong>
          <strong>{{bathroom.number}}</strong>
        </div>
        <div class="item-card">
          <label>Caracteristicas</label>
          <label>
            <input
              v-model="bathroom.pozo"
              type="checkbox"
              id="pozo"
              value="first_checkbox"
            > Pozo
          </label>
          <label>
            <input
              v-model="bathroom.regadera"
              type="checkbox"
              id="cbox1"
              value="first_checkbox"
            > Regadera
          </label>
          <label>
            <input
              v-model="bathroom.wc"
              type="checkbox"
              id="cbox1"
              value="first_checkbox"
            > W.C
          </label>
          <label>
            <input
              v-model="bathroom.fosa"
              type="checkbox"
              id="cbox1"
              value="first_checkbox"
            > Fosa Séptica
          </label>
        </div>
        <div class="buttons-options">
          <el-button
            v-if="bathroom.id"
            type="primary"
            icon="el-icon-refresh-right"
            round
            @click="bathroomUpdate(idx)"
          >
            Actualizar
          </el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            round
            @click="handleDelete(idx)"
          >
            Eliminar
          </el-button>
          <el-button
            v-if="!bathroom.id"
            type="success"
            icon="el-icon-plus"
            round
            @click="bathroomAdd(idx)"
          >
            Agregar
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    idAppointment: {
      type: Number,
    },
  },
  data() {
    return {
      bathroom: [],
      num: 0,
      antNumber: 0,
      minNum: 0,
      caracteristicas: [],
    };
  },
  methods: {
    handleAdd(num) {
      // const ejemp = [];
      const structBath = {
        number: num,
        // [num - 1]: ejemp,
      };
      if (num < this.antNumber) {
        this.bathroom.pop();
      } else {
        this.bathroom.push(structBath);
      }
      this.antNumber = num;
    },
    handleDelete(num) {
      try {
        const { id } = this.bathroom[num];
        this.axios.delete(`patient/social/deleteBathroom/master/${id}/${this.idAppointment}`)
          .then(() => {
            this.$notify({
              type: 'success',
              message: 'Eliminado con éxito',
            });
            if (num >= 0) {
              this.bathroom.splice(num, 1);
              this.num -= 1;
              this.minNum -= 1;
            }
            this.antNumber = num;
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              message: 'Problemas al eliminar',
            });
          });
      } catch (error) {
        this.$notify({
          type: 'error',
          message: 'Problemas al eliminar',
        });
      }
    },
    bathroomUpdate(idx) {
      try {
        this.loading = true;
        this.axios.put(`patient/social/updateBathroom/master/${this.idAppointment}`, {
          content: this.bathroom[idx],
        })
          .then(() => {
            this.$notify({
              type: 'success',
              message: 'Actualizado con éxito',
            });
          })
          .catch((err) => {
            this.$notify.error({
              message: err,
            });
          });
      } catch (error) {
        // console.log(error);
      }
    },
    bathroomAdd(idx) {
      try {
        this.loading = true;
        const { number } = this.bathroom[idx];
        this.axios.post(`patient/social/addBathroom/master/${this.idAppointment}`, {
          number,
          content: this.bathroom[idx],
        })
          .then((msg) => {
            if (msg.status === 204) {
              this.loading = false;
              this.$notify({
                type: 'success',
                message: 'Guardado con éxito',
              });
            }
            this.getAllBathrooms();
          })
          .catch(() => {
            // console.log(error);
          });
      } catch (error) {
        // console.log(error);
      }
    },
    async getAllBathrooms() {
      try {
        const res = await this.axios.get(`patient/social/getBathroom/master/${this.idAppointment}`);
        if (res) {
          const { data } = res;
          for (let i = 0; i < data.length; i++) {
            this.bathroom[i] = data[i];
          }
          this.num = data.length;
          this.minNum = data.length;
          this.num = data.length;
        } else {
          // console.log('nada');
        }
      } catch (error) {
        // console.log(error);
      }
    },
  },
  async created() {
    await this.getAllBathrooms();
  },
};
</script>

<style lang="scss">
.container-card-input {
  margin: 30px 0 30px 10px;
}
.add-item {
  margin-bottom: 20px;
}
.cards {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.card-general {
  width: 100%;
  background-color: rgb(255, 255, 255);
  border: solid 1px #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  strong {
    margin-right: 10px;
    font-size: 17px;
    color: rgb(133, 133, 133);
  }
  .card-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px;
    border-bottom: 1px solid #dde0e7;
    box-sizing: border-box;
  }
}
.card-general:hover {
  border: solid 3px rgba(154, 77, 241, 0.301);;
  transition: border-color .3s cubic-bezier(.645,.045,.355,1);
  box-shadow: 0 2px 12px 0 rgba(126, 77, 241, 0.11);
  .card-title {
    border-bottom: solid 1px rgba(77, 88, 241, 0.507);;
    transition: border-color .3s cubic-bezier(.645,.045,.355,1);
  }
}
.item-card {
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  label {
    font-size: 17px;
    color: rgb(58, 58, 58);
    margin: 10px 0px;
  }
}
.buttons-options {
  display: flex;
  width: 100%;
  padding: 15px;
  flex-direction: row;
  justify-content: center;
  button {
    display: flex;
    align-items: center;
    height: 30px;
  }
}
</style>
