<template>
  <el-container>
    <el-main>
      <div class="options">
        <el-button
          type="success"
          icon="el-icon-plus"
          @click="() => showDialogFamilyCore()"
        >
          Familiar nuclear
        </el-button>
      </div>
      <el-table
        :data="core"
        @row-click="(row) => showUpdateFamily(row)"
        stripe
      >
        <el-table-column
          prop="fullname"
          label="Nombre"
        />
        <el-table-column
          prop="relationship"
          label="Parentesco"
        />
        <el-table-column
          prop="isDependent"
          label="Dependiente"
        >
          <template slot-scope="scope">
            <label>{{ scope.row.isDependent ? 'Si' : 'No'}}</label>
          </template>
        </el-table-column>
        <el-table-column
          prop="hasDisability"
          label="Discapacidad"
        >
          <template slot-scope="scope">
            <label>{{ scope.row.hasDisability ? 'Si' : 'No'}}</label>
          </template>
        </el-table-column>
        <el-table-column
          prop="maritalStatus"
          label="Estado civil"
        />
        <el-table-column
          prop="age"
          label="Edad"
        >
          <template slot-scope="scope">
            <label>
              {{
                (scope.row.age === 0 && scope.row.birthdate)
                  ? $ageNumber(scope.row.birthdate)
                  : scope.row.age
              }}
            </label>
          </template>
        </el-table-column>
        <el-table-column
          prop="occupation"
          label="Ocupación"
        />
        <el-table-column
          prop="monthlyContribution"
          label="Aportación mensual"
        >
          <template slot-scope="scope">
            <label>
              {{ scope.row.monthlyContribution ?
                formatInput(scope.row.monthlyContribution) : 0}}
            </label>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-dialog
      :title= "`${familyCoreSelected.id !== null ? 'Modificar' : 'Agregar'} miembro familiar`"
      :visible.sync="showAddFamily"
      :before-close="() => closeDialog()"
      width="50%"
      status-icon
    >
      <el-form
        :model="familyMember"
        status-icon
        :rules="familyRules"
        ref="familyMember"
        class="add-family-dialog"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="fullname" label="Nombre completo">
              <el-input
                v-model="familyMember.fullname"
                :disabled="this.familyCoreSelected.self || false"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="age" label="Edad">
              <el-input
                v-model="familyMember.age"
                :disabled="this.familyCoreSelected.self || false"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="18">
          <el-col :span="6">
            <el-form-item prop="isDependent" label="Dependiente">
              <el-select v-model="familyMember.isDependent" >
                <el-option
                  v-for="item in tf"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="hasDisability" label="Discapacidad">
              <el-select v-model="familyMember.hasDisability" >
                <el-option
                  v-for="item in tf"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="maritalStatus" label="Estado civil">
              <el-select v-model="familyMember.maritalStatus" >
                <el-option
                  v-for="item in maritalStatusOpt"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="relationship" label="Parentesco">
          <el-input
            v-model="familyMember.relationship"
            :disabled="this.familyCoreSelected.self || false"
          />
        </el-form-item>
        <el-form-item prop="occupation" label="Ocupación">
          <el-input v-model="familyMember.occupation" />
        </el-form-item>
        <el-form-item prop="monthlyContribution" label="Aportación mensual en pesos">
          <el-input
            prefix-icon="el-icon-money"
            v-model="familyMember.monthlyContribution"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-row style="display: flex; justify-content: end; gap: 10px;">
          <el-button
            v-if="familyCoreSelected.id !== null"
            type="danger"
            :disabled="this.familyCoreSelected.self || false"
            @click="handleDelete"
          >
            Eliminar
          </el-button>
          <el-button
            :type="familyCoreSelected.id === null ? 'success' : 'primary'"
            @click="submitForm('familyMember')">
            {{ familyCoreSelected.id === null ? 'Agregar' : 'Actualizar' }}
          </el-button>
        </el-row>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import optionsMixin from '../../views/evaluations/SocioEconomic/mixin/options';

export default {
  props: {
    evaluationId: {
      type: Number,
      default: null,
    },
  },
  data() {
    function esEnteroPositivo(cadena) {
      return /^\d+$/.test(cadena);
    }
    const isDecimal = (rule, value, cb) => {
      const regexp = /^[0-9]+([,.][0-9]+)?$/g;
      // remove $ and ,
      const valueFormat = value.replace(/[$,]/g, '');
      if (!valueFormat) {
        return cb(new Error('El campo es requerido.'));
      }
      if (valueFormat && !regexp.test(valueFormat)) {
        return cb(new Error('El campo debe ser un número decimal.'));
      }
      return cb();
    };
    const checkAge = (rule, value, cb) => {
      if (value && !esEnteroPositivo(value)) {
        return cb(new Error('El campo debe ser un número entero positivo.'));
      }
      return cb();
    };
    return {
      patientId: null,
      showAddFamily: false,
      total: null,
      label: 'Por favor llene los datos requeridos para completar la Evaluación',
      isFirstEvaluation: true,
      core: [],
      appointmentId: null,
      familyRules: {
        fullname: [
          {
            required: true,
            message: 'El campo es requerido',
          },
        ],
        relationship: [
          {
            required: true,
            message: 'El campo es requerido',
          },
        ],
        maritalStatus: [
          {
            required: true,
            message: 'El campo es requerido',
          },
        ],
        isDependent: [
          {
            required: true,
            message: 'El campo es requerido',
          },
        ],
        hasDisability: [
          {
            required: true,
            message: 'El campo es requerido',
          },
        ],
        age: [
          {
            required: true,
            message: 'El campo es requerido',
          },
          { validator: checkAge, trigger: 'change' },
        ],
        occupation: [
          {
            required: true,
            message: 'El campo es requerido',
          },
        ],
        monthlyContribution: [
          {
            required: true,
            message: 'El campo es requerido',
            validator: isDecimal,
          },
        ],
      },
      familyCoreSelected: {
        id: null,
        fullname: null,
        relationship: null,
        occupation: null,
        monthlyContribution: null,
        age: null,
        self: false,
      },
      familyMember: {
        fullname: null,
        relationship: null,
        maritalStatus: null,
        isDependent: null,
        hasDisability: null,
        occupation: null,
        monthlyContribution: null,
        age: null,
      },
      form: {
        id: null,
        fullname: null,
        relationship: null,
        maritalStatus: null,
        isDependent: null,
        hasDisability: null,
        occupation: null,
        monthlyContribution: null,
        age: null,
      },
    };
  },
  mixins: [optionsMixin],
  computed: {
    sumAportaciones() {
      let income = 0;
      income += this.core
        .reduce((prev, curr) => prev
        + parseFloat(curr.monthlyContribution ?? 0), 0);
      this.sendInformation(income);
      return income;
    },
  },
  methods: {
    formatInput(value) {
      const inputValue = value.replace(/[^\d.]/g, '');

      if (inputValue) {
        const parseInput = parseFloat(inputValue);

        const formatValue = new Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN',
        }).format(parseInput);

        return formatValue;
      }
      return '';
    },
    submitForm(formName) {
      // eslint-disable-next-line consistent-return
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.familyCoreSelected.id !== null) {
            return this.updateFamily();
          }
          this.postFamily();
        } else {
          return false;
        }
      });
    },
    clearFamily() {
      this.familyCoreSelected = {
        id: null,
        fullname: null,
        relationship: null,
        occupation: null,
        monthlyContribution: null,
        age: null,
        self: false,
      };
    },
    showDialogFamilyCore() {
      this.showAddFamily = true;
      this.clearFamily();
      this.resetForm();
    },
    resetForm() {
      this.familyMember = {
        fullname: '',
        relationship: '',
        maritalStatus: '',
        isDependent: '',
        hasDisability: '',
        occupation: '',
        monthlyContribution: '',
        age: '',
      };
      this.$refs.familyMember.resetFields();
    },
    closeDialog() {
      this.showAddFamily = false;
      this.resetForm();
    },
    showUpdateFamily(row) {
      this.getFamilyMember(row.id).then((response) => {
        [this.familyCoreSelected] = response;
        [this.familyMember] = response;
        this.showAddFamily = true;
      });
    },
    handleDelete() {
      this.$confirm('¿Estás seguro de eliminar?')
        .then((_) => {
          this.deleteFamilyCore();
        })
        .catch((_) => {});
    },
    async updateFamily() {
      try {
        this.$loading();
        this.familyCoreSelected = {
          ...this.familyMember,
          orderRegister: this.core.length + 1,
        };
        const request = await this.axios.put(`patient/social/member/familycore/${this.familyCoreSelected.id}`, this.familyCoreSelected);
        if (request.status === 204) {
          this.$notify({
            title: 'Éxito',
            message: 'Miembro familiar actualizado correctamente',
            type: 'success',
          });
          this.closeDialog();
          await this.getData(this.patientId);
        }
        this.$emit('total-income', this.sumAportaciones);
        this.closeDialog();
        this.$loading().close();
      } catch (error) {
        this.$notify({
          title: 'Error',
          message: 'Ocurrió un error al actualizar el miembro familiar',
          type: 'error',
        });
      }
    },
    async getFamilyMember(memberId) {
      const family = [];
      const request = await this.axios.get(`patient/social/member/${memberId}`);
      if (request.data) {
        const { data } = request;
        data.forEach((element) => {
          family.push({
            id: element.id,
            fullname: element.fullname,
            relationship: element.relationship,
            occupation: element.occupation,
            hasDisability: element.hasDisability,
            isDependent: element.isDependent,
            maritalStatus: element.maritalStatus,
            monthlyContribution: element.monthlyContribution,
            otherContribution: element.otherContribution,
            age: element.age,
            self: element.self,
          });
        });
      }
      return family;
    },
    async postFamily() {
      try {
        this.familyCoreSelected = {
          ...this.familyMember,
          orderRegister: this.core.length + 1,
          self: false,
        };
        const response = await this.axios.post(`patient/social/member/familycore/${this.evaluationId}`, this.familyCoreSelected);
        if (response.status === 204) {
          this.$notify({
            title: 'Éxito',
            message: 'Miembro familiar agregado correctamente',
            type: 'success',
          });
          this.closeDialog();
          await this.getData(this.patientId);
          const total = this.sumAportaciones;
          this.$emit('total-income', total);
        }
      } catch (error) {
        this.$notify({
          title: 'Error',
          message: 'Ocurrió un error al agregar el miembro familiar',
          type: 'error',
        });
      }
    },
    async deleteFamilyCore() {
      try {
        const familyId = this.familyCoreSelected.id;
        const response = await this.axios.delete(`patient/social/member/familycore/${familyId}`);
        if (response.status === 204) {
          this.$notify({
            title: 'Éxito',
            message: 'Miembro familiar eliminado correctamente',
            type: 'success',
          });
          this.closeDialog();
          this.getData(this.patientId);
          this.$emit('total-income', this.sumAportaciones);
        }
      } catch (error) {
        this.$notify({
          title: 'Error',
          message: 'Ocurrió un error al eliminar la cita',
          type: 'error',
        });
      }
    },
    sendInformation(income) {
      this.$emit('total-income', income);
    },
    async getData(patientId) {
      try {
        const response = await this.axios.get(`patient/social/details/${patientId}`);

        if (response.data) {
          const { data } = response;
          this.form = data;
          this.createdAt = data?.createdAt;
          this.core = data.familyCore;
          if (this.core.length) {
            const self = this.core.find((x) => x.self);
            if (self) {
              this.core = [self, ...this.core.filter((x) => !x.self)];
            } else {
              this.core[0].self = true;
            }
          }
          this.sendInformation(this.sumAportaciones);
        }
        this.form.patient = patientId;
      } catch (error) {
        //
      }
    },
    async getAppointmentsSocial() {
      try {
        const response = await this.axios.get(`patient/social/getAppointmentSocial/${this.patientId}`);
        if (response.data) {
          this.appointmentId = response.data[0].id;
        }
      } catch (error) {
        this.$notify({
          title: 'Error',
          message: 'Ocurrió un error al obtener la cita',
          type: 'error',
        });
      }
    },
  },
  async created() {
    const { id } = this.$route.params;
    this.patientId = id;
    this.form.patient = id;
    await this.getData(id);
    this.getAppointmentsSocial();
  },
};
</script>

<style lang="scss" scoped>
.add-family-dialog {
  padding: 30px;
  overflow-y: auto;
}
.options {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
</style>
