<template>
  <el-row class="row" id="socialEvaluation" :key="componentKey">
    <el-col class="col">
      <Title2
        v-if="appointmentId"
        btnText="VOLVER"
        :title="'ACTUALIZAR APARTADO DE TRABAJO SOCIAL'"
        :label="label"
        v-on:before-submit="volver"
        :createdAt="createdAt"
        :appointment="appointmentId"
        :patientId="patientId"
        :appointmentType="'social'"
      />
      <div class="form">
        <el-container>
          <el-main>
            <el-form
              label-position="top"
              :model="form"
              ref="refId"
              :rules="rules"
              @presubmit.prevent
            >
              <div class="row-no-center">
                <div style="flex: 1;">
                  <div>
                    <p class="label-style">DATOS GENERALES</p>
                    <el-form-item
                      prop="motivoEvaluacion"
                      style="width: 250px"
                      label="Motivo de la Evaluación"
                    >
                      <el-input v-model="form.motivoEvaluacion" type="textarea"/>
                    </el-form-item>
                    <el-form-item
                      prop="residenceTimeAtTijuana"
                      style="width: 250px"
                      label="Tiempo de residencia en Tijuana"
                    >
                      <el-input v-model="form.residenceTimeAtTijuana" type="textarea"/>
                    </el-form-item>
                  </div>
                  <div>
                    <p class="label-style">LÍNEA DE BIENESTAR</p>
                    <div class="row-no-center">
                      <el-form-item class="medium" prop="academicDegree" label="Escolaridad">
                        <el-select v-model="form.academicDegree">
                          <el-option
                            v-for="item in academicDegree"
                            :key="item.value"
                            :label="item.label"
                            :value="item.label"
                          />
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        class="medium"
                        prop="houseCondition"
                        label="Condiciones de la vivienda"
                      >
                        <el-select v-model="form.houseCondition">
                          <el-option
                            v-for="item in houseCondition"
                            :key="item.value"
                            :label="item.label"
                            :value="item.label"
                          />
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div>
                  <p class="label-style">CARACTERISTICAS DE LA VIVIENDA</p>
                  <div class="row-no-center">
                    <el-form-item class="medium" prop="houseWalls" label="Paredes">
                      <el-select v-model="form.houseWalls">
                        <el-option
                          v-for="item in houseWalls"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item class="medium" prop="houseRoof" label="Techo">
                      <el-select v-model="form.houseRoof">
                        <el-option
                          v-for="item in houseRoof"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item class="medium" prop="houseFloor" label="Piso">
                      <el-select v-model="form.houseFloor">
                        <el-option
                          v-for="item in houseFloor"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label"
                        />
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                </div>
                <div class="file-container">
                  <div class="file" @click="docsVisible = true"/>
                  <p class="lbl1">Adjuntar Imagen / Documento</p>
                  <p class="lbl2">referencia del nivel socioeconómico del paciente</p>
                </div>
              </div>
              <div style="margin-top:20px;">
                <p class="label-style">CUARTOS DE LA VIVIENDA</p>
                <div class="row-no-center">
                  <el-checkbox v-model="form.houseRoomsLivingRoom">Sala</el-checkbox>
                  <el-checkbox v-model="form.houseRoomsDiningRoom">Comedor</el-checkbox>
                  <el-checkbox v-model="form.houseRoomsKitchen">Cocina</el-checkbox>
                  <el-checkbox v-model="form.houseRoomsBathroom">Baño</el-checkbox>
                  <el-checkbox v-model="houseRoomsBedroom">Recámara</el-checkbox>
                  <el-checkbox v-model="form.houseRoomsStudyingRoom">Estudio</el-checkbox>
                  <el-checkbox v-model="form.houseRoomsCourtyard">Patio</el-checkbox>
                  <el-checkbox v-model="form.houseRoomsOther">Otro</el-checkbox>
                </div>
                <bathroom
                  v-if="form.houseRoomsBathroom"
                  :idAppointment="appointmentId"
                />
                <el-form-item
                  class="medium"
                  prop="houseRoomsBedroom"
                  label="Cantidad de recámaras"
                  v-if="houseRoomsBedroom"
                >
                  <el-input-number v-model="form.houseRoomsBedroom" :min="1" />
                </el-form-item>
              </div>
              <div style="margin-top:20px;">
                <p class="label-style">SERVICIOS DE LA VIVIENDA</p>
                <div class="row-no-center">
                  <el-checkbox v-model="form.serviceLight">
                    Instalación de luz
                  </el-checkbox>
                  <el-checkbox v-model="form.serviceSewerSystem">
                    Drenaje y alcantarillado
                  </el-checkbox>
                  <el-checkbox v-model="form.serviceCableTV">
                    Televisión por cable
                  </el-checkbox>
                  <el-checkbox v-model="form.servicePhone">
                    Teléfono fijo
                  </el-checkbox>
                  <el-checkbox v-model="form.serviceInternet">
                    Internet
                  </el-checkbox>
                  <el-checkbox v-model="form.serviceAsphaltStreet">
                    Pavimento en las calles
                  </el-checkbox>
                  <el-checkbox v-model="form.servicePublicWater">
                    Agua Pública
                  </el-checkbox>
                  <el-checkbox v-model="form.serviceGaslp">
                    Gas LP
                  </el-checkbox>
                  <el-checkbox v-model="form.serviceOther">
                    Otro
                  </el-checkbox>
                </div>
              </div>
              <div style="margin-top:20px;">
                <p class="label-style">MUEBLES Y ELECTRODOMÉSTICOS</p>
                <div class="row-no-center">
                  <el-checkbox v-model="form.houseStuffTV">
                    Televisión
                  </el-checkbox>
                  <el-checkbox v-model="form.houseStuffFridge">
                    Refrigerador
                  </el-checkbox>
                  <el-checkbox v-model="form.houseStuffStove">
                    Estufa
                  </el-checkbox>
                  <el-checkbox v-model="form.houseStuffDishWasher">
                    Parrilla eléctrica
                  </el-checkbox>
                  <el-checkbox v-model="form.houseStuffMicrowaveOven">
                    Horno de Microondas
                  </el-checkbox>
                  <el-checkbox v-model="form.houseStuffBlender">
                    Licuadora
                  </el-checkbox>
                  <el-checkbox v-model="form.houseStuffWashingMachine">
                    Lavadora
                  </el-checkbox>
                  <el-checkbox v-model="form.houseStuffDryer">
                    Secadora
                  </el-checkbox>
                </div>
                <div class="row-no-center">
                  <el-checkbox v-model="form.houseStuffComputer">
                    Computadora
                   o Ipad</el-checkbox>
                  <el-checkbox v-model="form.houseStuffDiningRoomSet">
                    Comedor
                  </el-checkbox>
                  <el-checkbox v-model="form.houseStuffLivingRoomSet">
                    Sala
                  </el-checkbox>
                  <el-checkbox v-model="form.houseStuffBedroomSet">
                    Recámara
                  </el-checkbox>
                </div>
              </div>
              <div style="margin-top:20px;">
                <el-button
                  type="primary"
                  size="small"
                  @click="presubmit"
                  >
                  Guardar Datos Generales
                </el-button>
                <div style="margin-top:20px;" v-if="evaluationId">
                  <el-divider />
                  <p class="label-style" style="margin-bottom:10px;">CUENTA CON AUTO PROPIO</p>
                  <automoviles
                    :appointmentId="appointmentId"
                    :evaluationId="evaluationId"
                  />
                </div>
              </div>
              <div style="margin-top: 20px;" v-if="evaluationId">
                <p class="label-style" style="margin-bottom:10px;">ESTRUCTURA FAMILIAR</p>
                <strong>NUCLEAR:</strong><br>
                <label>
                  Número de personas ocupadas de 14 años y
                  más que vivan en la unidad doméstica, incluyendo el beneficiario.
                </label>
                <estructuraFamiliar
                  :evaluationId="evaluationId"
                  v-on:total-income="(val) => callbackIncome(val, 'core')"
                />
              </div>
              <div style="margin-top: 20px;" v-if="evaluationId">
                <strong>EXTENDIDA:</strong><br>
                <label>
                  Número de personas ocupadas de 14 años
                  y más que apoyen a la economía de la unidad doméstica.
                </label>
                <estruturaFamiliarExtendida
                  :evaluationId="evaluationId"
                  v-on:total-income="(val) => callbackIncome(val, 'wide')"
                />
              </div>
              <div style="margin-top:20px;" v-if="evaluationId">
                <el-divider />
                <p class="label-style">EGRESOS MENSUALES EN PROMEDIO</p>
                <div class="row-no-center">
                  <el-form-item prop="expenseWater" class="medium" label="Agua">
                    <el-input v-model="form.expenseWater" />
                  </el-form-item>
                  <el-form-item prop="expenseLight" class="medium" label="Luz">
                    <el-input v-model="form.expenseLight" />
                  </el-form-item>
                  <el-form-item prop="expensePhone" class="medium" label="Teléfono / Internet Fijo">
                    <el-input v-model="form.expensePhone" />
                  </el-form-item>
                  <el-form-item prop="expenseCellPhone" class="medium" label="Celular">
                    <el-input v-model="form.expenseCellPhone" />
                  </el-form-item>
                  <el-form-item prop="expenseGas" class="medium" label="Gas">
                    <el-input v-model="form.expenseGas" />
                  </el-form-item>
                </div>
                <div class="row-no-center">
                  <el-form-item prop="expenseFood" class="medium lh" label="Alimentación">
                    <el-input v-model="form.expenseFood" />
                  </el-form-item>
                  <el-form-item
                    prop="expenseHouseSupplies"
                    class="medium"
                    label="Productos de limpieza o mantenimiento del hogar"
                  >
                    <el-input v-model="form.expenseHouseSupplies" />
                  </el-form-item>
                  <el-form-item prop="expenseHealth" class="medium lh" label="Salud">
                    <el-input v-model="form.expenseHealth" />
                  </el-form-item>
                  <el-form-item prop="expenseSupport" class="medium lh" label="Gastos de cuidado">
                    <el-input v-model="form.expenseSupport" />
                  </el-form-item>
                  <el-form-item prop="expenseEducation" class="medium lh" label="Educación">
                    <el-input v-model="form.expenseEducation" />
                  </el-form-item>
                  <el-form-item prop="expenseRent" class="medium lh" label="Renta">
                    <el-input v-model="form.expenseRent" />
                  </el-form-item>
                </div>
                <div class="row-no-center">
                  <el-form-item
                    prop="expenseTransport"
                    class="medium"
                    label="Gasolina y/o transporte público"
                  >
                    <el-input v-model="form.expenseTransport" />
                  </el-form-item>
                  <el-form-item prop="expenseCreditCard" class="medium" label="Tarjetas de crédito">
                    <el-input v-model="form.expenseCreditCard" />
                  </el-form-item>
                  <el-form-item prop="expenseOther" class="medium" label="Otros">
                    <el-input v-model="form.expenseOther" />
                  </el-form-item>
                </div>

                <div class="row-no-center">
                  <div>
                    <p class="label-style">TOTAL INGRESOS</p>
                    <el-tag type="success" v-if="ingresos === 0">
                      {{ currencyFormater(incomeTotal) }}
                    </el-tag>
                    <el-tag type="success" v-else>
                      {{ currencyFormater(ingresos) }}
                    </el-tag>
                  </div>
                  <div style="width:20px" />
                  <div>
                    <p class="label-style">TOTAL EGRESOS</p>
                    <el-tag type="warning">
                      {{ currencyFormater(outcomeTotal) }}
                    </el-tag>
                  </div>
                  <div style="width:20px" />
                  <div>
                    <p class="label-style">REMANENTE O DEUDA</p>
                    <el-tag type="info" v-if="ingresos === 0">
                      <b>
                        {{ currencyFormater(incomeTotal - outcomeTotal) }}
                      </b>
                    </el-tag>
                    <el-tag type="info" v-else>
                      <b>
                        {{ currencyFormater(ingresos - outcomeTotal) }}
                      </b>
                    </el-tag>
                  </div>
                </div>

                <el-form-item
                  class="med-lg"
                  prop="incomeReceipts"
                  label="Se mostraron comprobantes de ingresos"
                  style="margin-top:10px;"
                >
                  <el-select v-model="form.incomeReceipts">
                    <el-option
                      v-for="item in tf"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  class="med-lg"
                  prop="outcomeReceipts"
                  label="Se mostraron comprobantes de egresos"
                  style="margin-top:10px;"
                >
                  <el-select v-model="form.outcomeReceipts">
                    <el-option
                      v-for="item in tf"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-button
                  type="primary"
                  size="small"
                  style="margin-top:10px; margin-bottom:10px;"
                  @click="presubmit"
                >
                  Guardar Egresos Mensuales
                </el-button>
                <div style="color:#606266; font-size:15px;" v-if="affiliation != null">
                  <label>
                    Afiliación:
                  </label>
                  <label>
                    {{affiliation}}
                  </label>
                </div>
                <div style="color:#606266; font-size:15px;" v-if="affiliation == null">
                  <label>
                    Afiliación:
                  </label>
                  <label>
                    Sin Afiliación
                  </label>
                </div>
              </div>
              <div style="margin-top:20px;" v-if="evaluationId">
                <el-divider />
                <p class="label-style">CUALITATIVO</p>
                <div style="margin-top: 15px;">
                  <el-form-item
                    prop="familySituationDescription"
                    class="large"
                    label="Descripción de la situación familiar"
                    style="width:80%;"
                  >
                    <el-input
                      v-model="form.familySituationDescription"
                      type="textarea"
                      :autosize="true"
                    />
                  </el-form-item>
                  <el-form-item
                    prop="comments"
                    class="large"
                    label="Comentarios de la Trabajodora Social"
                    style="width:80%;"
                  >
                    <el-input
                      v-model="form.comments"
                      type="textarea"
                      :autosize="{ minRows: 3}"
                    />
                  </el-form-item>
                </div>
                <div>
                  <p class="label-style">PERFIL</p>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item
                        label="Clasificación"
                        style="margin-top:10px;"
                      >
                        <el-select v-model="form.classification">
                          <el-option
                            v-for="item in perfilOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                        class="medium"
                        label="Evaluación de Trabajadora Social"
                        style="margin-top:10px;"
                        prop="evaluationResult"
                      >
                        <el-select v-model="form.evaluationResult">
                          <el-option
                            v-for="item in evaluationResult"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <div v-if="form.evaluationResult === evaluationResult[1].value">
                        <el-form-item
                          class="medium"
                          label="Área"
                          style="margin-top:10px;"
                        >
                          <el-select v-model="form.area">
                            <el-option
                              v-for="item in optionsClasifica"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          v-if="form.area === 'cirugia'"
                          class="medium"
                          label="Costo"
                          style="margin-top:10px;"
                        >
                          <el-select v-model="form.cost">
                            <el-option
                              v-for="item in optionsCosto"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>
                        <div
                          class="cirugia"
                          v-if="form.cost === 'sin costo' && form.area === 'cirugia'
                            || (form.cost === 'con costo')"
                        >
                          <div class="cirugia-card">
                            <Cirugias
                              v-if="evaluationId"
                              :costo="form.cost"
                              :evaluationId="evaluationId"
                            />
                          </div>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <el-button
                  type="primary"
                  size="small"
                  @click="presubmit"
                  >
                    Guardar Datos Cualitativos
                </el-button>
              </div>
              <el-form-item style="width: 100%!important;">
                <el-button
                  type="success"
                  @click="docsVisible = true"
                  class="files"
                  style="margin-left:0px; float:right; margin-right:10px"
                >
                  ADJUNTAR ARCHIVOS
                </el-button>
              </el-form-item>
            </el-form>
          </el-main>
        </el-container>
        <DocsDialog
          :docsVisible="docsVisible"
          :folder="'TRABAJO SOCIAL'"
          v-on:close-dialog="closeDialog()"
        />
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { mapState } from 'vuex';
import lodash from 'lodash';
import Title2 from '../../../components/Title2';
import optionsMixin from './mixin/options';
import currencyFormater from '../../../utils/currencyFormater';
import DocsDialog from '../../Docs/DocsDialog';
import bathroom from '../../../components/social/components/bathroom';
import Cirugias from '../../../components/social/components/cirugias';
import estructuraFamiliar from '../../../components/social/estructuraFamiliar';
import estruturaFamiliarExtendida from '../../../components/social/estruturaFamiliarExtendida';
import automoviles from '../../../components/social/automoviles';

export default {
  data() {
    const isDecimal = (rule, value, cb) => {
      const regexp = /^[0-9]+([,.][0-9]+)?$/g;
      if (value && !regexp.test(value)) {
        return cb(new Error('El campo debe ser un numero decimal.'));
      }
      return cb();
    };
    return {
      ingresos: 0,
      evaluationId: null,
      customToolbar: [
        [
          'bold',
          'italic',
          'underline',
        ],
        [
          {
            list: 'ordered',
          },
          {
            list: 'bullet',
          },
        ],
      ],
      isFirstStatus: false,
      patientId: null,
      componentKey: 0,
      docsVisible: false,
      label: 'Por favor llene los datos requeridos para completar la Evaluación',
      createdAt: '',
      appointmentId: '',
      isFirstEvaluation: true,
      bathroom: false,
      houseRoomsBedroom: false,
      num: null,
      perfilOptions: [
        {
          value: 'A/B+',
          label: 'A/B+',
        },
        {
          value: 'C+',
          label: 'C+',
        },
        {
          value: 'C',
          label: 'C',
        },
        {
          value: 'D+',
          label: 'D+',
        },
        {
          value: 'D',
          label: 'D',
        },
        {
          value: 'E',
          label: 'E',
        },
      ],
      optionsClasifica: [
        {
          label: 'Cirugía',
          value: 'cirugia',
        },
        {
          label: 'Atención',
          value: 'atención',
        },
      ],
      optionsCosto: [
        {
          label: 'Con Costo',
          value: 'con costo',
        },
        {
          label: 'Sin Costo',
          value: 'sin costo',
        },
      ],
      optionsMoneda: [
        {
          label: 'USD',
          value: 'USD',
        },
        {
          label: 'MXN',
          value: 'MXN',
        },
      ],
      form: {
        motivoEvaluacion: null,
        residenceTimeAtTijuana: null,
        academicDegree: null,
        houseCodition: null,
        houseWalls: null,
        houseFloor: null,
        houseRoof: null,
        houseRoomsLivingRoom: false,
        houseRoomsDiningRoom: false,
        houseRoomsKitchen: false,
        houseRoomsBathroom: false,
        houseRoomsBedroom: 0,
        houseRoomsStudyingRoom: false,
        houseRoomsCourtyard: false,
        houseRoomsOther: false,
        serviceLight: false,
        serviceSewerSystem: false,
        serviceCableTV: false,
        servicePhone: false,
        serviceInternet: false,
        serviceAsphaltStreet: false,
        servicePublicWater: false,
        serviceGaslp: false,
        serviceOther: false,
        houseStuffFridge: false,
        houseStuffStove: false,
        houseStuffMicrowaveOven: false,
        houseStuffBlender: false,
        houseStuffWashingMachine: false,
        houseStuffDryer: false,
        houseStuffComputer: false,
        houseStuffDiningRoomSet: false,
        houseStuffLivingRoomSet: false,
        houseStuffBedroomSet: false,
        houseStuffTV: false,
        houseStuffDishWasher: false,
        car: null,
        expenseWater: null,
        expenseLight: null,
        expensePhone: null,
        expenseCellPhone: null,
        expenseGas: null,
        expenseFood: null,
        expenseHouseSupplies: null,
        expenseHealth: null,
        expenseSupport: null,
        expenseEducation: null,
        expenseRent: null,
        expenseTransport: null,
        expenseCreditCard: null,
        expenseOther: null,
        incomeReceipts: null,
        outcomeReceipts: null,
        familySituationDescription: null,
        comments: null,
        evaluationResult: null,
        programApplied: null,
        perfil: null,
        currency: 'USD',
        precio: null,
        cost: null,
        area: null,
        classification: null,
      },
      initialForm: {},
      affiliation: '',
      core: [],
      initialCore: [],
      wide: [],
      initialWide: [],
      familyRules: {
        fullname: [
          {
            required: true,
            message: 'El campo es requerido',
          },
        ],
        relationship: [
          {
            required: true,
            message: 'El campo es requerido',
          },
        ],
        age: [
          {
            required: true,
            message: 'El campo es requerido',
          },
        ],
        occupation: [
          {
            required: true,
            message: 'El campo es requerido',
          },
        ],
        monthlyContribution: [
          {
            validator: isDecimal,
          },
        ],
        otherContribution: [
          {
            validator: isDecimal,
          },
        ],
      },
      rules: {
        expenseWater: [
          {
            validator: isDecimal,
          },
        ],
        expenseLight: [
          {
            validator: isDecimal,
          },
        ],
        expensePhone: [
          {
            validator: isDecimal,
          },
        ],
        expenseCellPhone: [
          {
            validator: isDecimal,
          },
        ],
        expenseGas: [
          {
            validator: isDecimal,
          },
        ],
        expenseFood: [
          {
            validator: isDecimal,
          },
        ],
        expenseHouseSupplies: [
          {
            validator: isDecimal,
          },
        ],
        expenseHealth: [
          {
            validator: isDecimal,
          },
        ],
        expenseSupport: [
          {
            validator: isDecimal,
          },
        ],
        expenseEducation: [
          {
            validator: isDecimal,
          },
        ],
        expenseRent: [
          {
            validator: isDecimal,
          },
        ],
        expenseTransport: [
          {
            validator: isDecimal,
          },
        ],
        expenseCreditCard: [
          {
            validator: isDecimal,
          },
        ],
        expenseOther: [
          {
            validator: isDecimal,
          },
        ],

      },
      loadingService: null,
      loading: false,
      hasSelfOccupation: false,
      anteriorValue: 0,
      incomeCore: 0,
      incomeWide: 0,
    };
  },
  mixins: [optionsMixin],
  components: {
    Title2,
    DocsDialog,
    bathroom,
    Cirugias,
    estructuraFamiliar,
    estruturaFamiliarExtendida,
    automoviles,
  },
  computed: {
    ...mapState({
      role: (state) => state.auth.user.role,
    }),
    incomeTotal() {
      let income = 0;
      income += this.core
        .reduce((prev, curr) => prev + parseFloat(curr.monthlyContribution ?? 0), 0);
      income += this.wide.reduce((prev, curr) => prev
        + parseFloat(curr?.monthlyContribution ?? 0) + parseFloat(curr?.otherContribution ?? 0), 0);
      return income;
    },
    outcomeTotal() {
      let outcome = 0;
      outcome += parseFloat((this.form.expenseWater ?? 0) || (this.form.expenseWater === 'NaN' ? null : 0));
      outcome += parseFloat((this.form.expenseLight ?? 0) || (this.form.expenseLight === 'NaN' ? null : 0));
      outcome += parseFloat((this.form.expensePhone ?? 0) || (this.form.expensePhone === 'NaN' ? null : 0));
      outcome += parseFloat((this.form.expenseCellPhone ?? 0) || (this.form.expenseCellPhone === 'NaN' ? null : 0));
      outcome += parseFloat((this.form.expenseGas ?? 0) || (this.form.expenseGas === 'NaN' ? null : 0));
      outcome += parseFloat((this.form.expenseFood ?? 0) || (this.form.expenseFood === 'NaN' ? null : 0));
      outcome += parseFloat((this.form.expenseHouseSupplies ?? 0) || (this.form.expenseHouseSupplies === 'NaN' ? null : 0));
      outcome += parseFloat((this.form.expenseHealth ?? 0) || (this.form.expenseHealth === 'NaN' ? null : 0));
      outcome += parseFloat((this.form.expenseSupport ?? 0) || (this.form.expenseSupport === 'NaN' ? null : 0));
      outcome += parseFloat((this.form.expenseEducation ?? 0) || (this.form.expenseEducation === 'NaN' ? null : 0));
      outcome += parseFloat((this.form.expenseRent ?? 0) || (this.form.expenseRent === 'NaN' ? null : 0));
      outcome += parseFloat((this.form.expenseTransport ?? 0) || (this.form.expenseTransport === 'NaN' ? null : 0));
      outcome += parseFloat((this.form.expenseCreditCard ?? 0) || (this.form.expenseCreditCard === 'NaN' ? null : 0));
      outcome += parseFloat((this.form.expenseOther ?? 0) || (this.form.expenseOther === 'NaN' ? null : 0));
      return outcome;
    },
  },
  methods: {
    callbackIncome(total, type) {
      if (type === 'core') {
        this.incomeCore = total;
      } else if (type === 'wide') {
        this.incomeWide = total;
      }
      const income = this.incomeCore + this.incomeWide;
      this.ingresos = parseFloat(income).toFixed(2);
    },
    closeDialog() {
      this.docsVisible = false;
    },
    generateEmptyWide() {
      return {
        id: 0,
        fullname: null,
        relationship: null,
        occupation: null,
        monthlyContribution: null,
        otherContribution: null,
      };
    },
    format() {
      return {
        patient: this.form.patient,
        motivoEvaluacion: this.form.motivoEvaluacion,
        residenceTimeAtTijuana: this.form.residenceTimeAtTijuana,
        academicDegree: this.form.academicDegree,
        houseCondition: this.form.houseCondition,
        houseWalls: this.form.houseWalls,
        houseFloor: this.form.houseFloor,
        houseRoof: this.form.houseRoof,
        houseRoomsLivingRoom: this.form.houseRoomsLivingRoom,
        houseRoomsDiningRoom: this.form.houseRoomsDiningRoom,
        houseRoomsKitchen: this.form.houseRoomsKitchen,
        houseRoomsBathroom: this.form.houseRoomsBathroom,
        houseRoomsBedroom: this.houseRoomsBedroom ? this.form.houseRoomsBedroom : null,
        houseRoomsStudyingRoom: this.form.houseRoomsStudyingRoom,
        houseRoomsCourtyard: this.form.houseRoomsCourtyard,
        houseRoomsOther: this.form.houseRoomsOther,
        serviceLight: this.form.serviceLight,
        serviceSewerSystem: this.form.serviceSewerSystem,
        serviceCableTV: this.form.serviceCableTV,
        servicePhone: this.form.servicePhone,
        serviceInternet: this.form.serviceInternet,
        serviceAsphaltStreet: this.form.serviceAsphaltStreet,
        servicePublicWater: this.form.servicePublicWater,
        serviceGaslp: this.form.serviceGaslp,
        serviceOther: this.form.serviceOther,
        houseStuffFridge: this.form.houseStuffFridge,
        houseStuffStove: this.form.houseStuffStove,
        houseStuffMicrowaveOven: this.form.houseStuffMicrowaveOven,
        houseStuffBlender: this.form.houseStuffBlender,
        houseStuffWashingMachine: this.form.houseStuffWashingMachine,
        houseStuffDryer: this.form.houseStuffDryer,
        houseStuffComputer: this.form.houseStuffComputer,
        houseStuffDiningRoomSet: this.form.houseStuffDiningRoomSet,
        houseStuffLivingRoomSet: this.form.houseStuffLivingRoomSet,
        houseStuffBedroomSet: this.form.houseStuffBedroomSet,
        houseStuffTV: this.form.houseStuffTV,
        houseStuffDishWasher: this.form.houseStuffDishWasher,
        car: this.form.car,
        expenseWater: parseFloat(this.form.expenseWater),
        expenseLight: parseFloat(this.form.expenseLight),
        expensePhone: parseFloat(this.form.expensePhone),
        expenseCellPhone: parseFloat(this.form.expenseCellPhone),
        expenseGas: parseFloat(this.form.expenseGas),
        expenseFood: parseFloat(this.form.expenseFood),
        expenseHouseSupplies: parseFloat(this.form.expenseHouseSupplies),
        expenseHealth: parseFloat(this.form.expenseHealth),
        expenseSupport: parseFloat(this.form.expenseSupport),
        expenseEducation: parseFloat(this.form.expenseEducation),
        expenseRent: parseFloat(this.form.expenseRent),
        expenseTransport: parseFloat(this.form.expenseTransport),
        expenseCreditCard: parseFloat(this.form.expenseCreditCard),
        expenseOther: parseFloat(this.form.expenseOther),
        incomeReceipts: this.form.incomeReceipts,
        outcomeReceipts: this.form.outcomeReceipts,
        familySituationDescription: this.form.familySituationDescription,
        comments: this.form.comments,
        evaluationResult: this.form.evaluationResult,
        programApplied: this.form.evaluationResult === this.evaluationResult[1].value
          ? this.form.programApplied : null,
        cost: this.form.cost,
        classification: this.form.classification,
        currency: this.form.currency,
        area: this.form.area,
      };
    },
    getDiff(obj1 = {}, obj2 = {}, diff = [], keyMap = '') {
      Object.keys(obj1).forEach((key) => {
        if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
          const diffR = this.getDiff(obj1[key], obj2[key], diff, `${keyMap}${key}.`);
          diff.concat(diffR);
        } else if (!lodash.isEqual(obj1[key], obj2[key])) {
          let keyMapFormatted = `${keyMap}${key}`;
          keyMapFormatted = keyMapFormatted.replace(/[0-9]$/g, '');
          keyMapFormatted = keyMapFormatted[keyMapFormatted.length - 1] === '.'
            ? keyMapFormatted.slice(0, -1) : keyMapFormatted;
          diff.push(keyMapFormatted);
        }
      });
      return diff;
    },
    async changeStatus(patient, appointment) {
      try {
        if (this.isFirstStatus === true) {
          await this.axios.post(`patient/status/social/${patient}/${appointment}`, {
            statusId: 5,
          });
        } else if (this.statusId !== 5) {
          await this.axios.put(`patient/status/social/${patient}/${appointment}`, {
            statusId: 5,
          });
        }
      } catch (error) {
        // some code
      }
    },
    async presubmit() {
      try {
        this.$loading();
        const requests = [];
        const data = this.format();
        if (this.isFirstEvaluation) {
          requests.push(this.axios.post(`patient/social/${this.patientId}/${this.appointmentId}`, data));
          requests.push(this.axios.post(`patient/social/family/core/${this.patientId}/${this.appointmentId}`, {
            ...this.core[0],
            monthlyContribution: parseFloat(0),
          }));
          const [res] = await Promise.all(requests.map((item) => item.catch((error) => error)));
          if (res.data) {
            this.evaluationId = res?.data?.request?.id;
            this.isFirstEvaluation = false;
            await this.axios.post(`patient/changelog/${this.patientId}`, {
              title: 'EVALUACIÓN SOCIOECONÓMICA',
              message: 'Se ha agregado evaluación socioeconómica',
            });
          }
        } else {
          const formDiff = this.getDiff(this.initialForm, this.form);
          let message;
          if (formDiff.length) {
            message = 'Se modificó el perfil socioeconómico';
          }
          const response = await this.axios.put(`patient/social/${this.patientId}/${this.appointmentId}`, data);
          if (response.length) {
            this.axios.post(`patient/changelog/${this.patientId}`, {
              title: 'EVALUACIÓN SOCIOECONÓMICA',
              message,
            });
          }
        }
        await this.changeStatus(this.patientId, this.appointmentId);
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          message: `No se pudo agregar evaluación socioeconómica ${error}}`,
        });
      } finally {
        this.$loading().close();
      }
    },
    async volver() {
      setTimeout(() => { this.$router.replace('/'); });
    },
    async getData() {
      try {
        const response = await this.axios.get(`patient/social/details/${this.patientId}`);
        try {
          const res = await this.axios.get(`patient/profile/${this.patientId}`);
          this.affiliation = res.data?.affiliation || '';
          this.isFirstEvaluation = this.appointmentId !== response.data?.appointmentId;
        } catch {
          this.$notify({
            type: 'error',
            title: 'Error',
            message: 'No se pudo obtener la afiliación del paciente',
          });
        }

        if (response.data) {
          const { data } = response;
          this.evaluationId = this.isFirstEvaluation ? null : data.id;
          this.form = data;
          this.createdAt = data?.createdAt;
          this.core = data.familyCore;

          const { houseRoomsBedroom } = data;
          this.houseRoomsBedroom = !!houseRoomsBedroom;

          const formClone = JSON.parse(JSON.stringify(this.form));
          this.initialForm = formClone;

          const coreClone = JSON.parse(JSON.stringify(this.core));
          this.initialCore = coreClone;

          if (this.core.length) {
            const self = this.core.find((x) => x.self);
            if (self) {
              this.core = [self, ...this.core.filter((x) => !x.self)];
            } else {
              this.core[0].self = true;
            }
          }
        }

        const response2 = await this.axios.get(`patient/profile/${this.patientId}`);
        const response3 = await this.axios.get(`patient/info/${this.patientId}`);

        if (response2 && response3) {
          this.core.push({
            self: true,
            fullname: `${response3.data.firstName} ${response3.data.lastName}`,
            relationship: 'N/A',
            occupation: response2.data.occupation || 'Sin registro',
            monthlyContribution: 0,
            birthdate: response3.data.birthdate,
            maritalStatus: response2.data.maritalStatus,
          });
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    currencyFormater,
    async getAppointmentsSocial() {
      try {
        const response = await this.axios.get(`patient/social/getAppointmentSocial/${this.patientId}`);
        if (response.data) {
          this.appointmentId = response.data[0].id;
          await this.getStatus(this.patientId, this.appointmentId);
          await this.getData();
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    async getStatus(patientId, appointmentId) {
      const request = await this.axios(`patient/status/social/${patientId}/${appointmentId}`);
      if (!request.data) {
        this.isFirstStatus = true;
      } else {
        this.statusId = request.data.id;
      }
    },
  },
  async created() {
    this.$loading();
    const { id } = this.$route.params;
    this.patientId = id;
    this.form.patient = id;
    await this.getAppointmentsSocial();
    this.$loading().close();
  },
};
</script>

<style lang="scss">
@import './styles/style.scss';
.cirugia {
  margin: 20px 0px 20px 20px;
}
</style>
<style lang="scss" scoped>
@import './styles/style-scoped.scss';
</style>
