<template>
  <div class="container-card-input">
    <div class="add-item">
      <el-button
        round
        @click="handleAdd(num)"
      ><i class="el-icon-plus"/>
        Agregar cirugía
      </el-button>
    </div>
    <div class='cards' v-if="cirugias.length >= 1">
      <div class="card-general" v-for="(cirugias, idx) in cirugias" :key="idx">
        <div class="card-title">
          <strong>Cirugía</strong>
        </div>
        <div class="item-card">
          <label>Lado</label>
          <el-select v-model="cirugias.eye" placeholder="Select" size="medium">
            <el-option
              v-for="item in ladoOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <label>Especialidad</label>
          <el-select
            v-model="cirugias.specialty"
            placeholder="Select"
            size="medium"
            @change="cirugias.type = null"
          >
            <el-option
              v-for="item in specialtyOptions"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <label>Tipo de cirugía</label>
          <el-select
            v-model="cirugias.type"
            placeholder="Select"
            size="medium"
            v-if="type[0][cirugias.specialty] != null "
          >
            <el-option
              v-for="item in type[0][cirugias.specialty]"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-input
            v-model="cirugias.type"
            size="medium"
            v-if="type[0][cirugias.specialty] == null"
          />
          <div v-if="costo === 'con costo'">
            <label>Precio</label>
            <el-input v-model="cirugias.price" size="medium"/>
          </div>
          <div v-if="costo === 'con costo'" style="display: flex; flex-direction: column;">
            <label>Moneda</label>
            <el-select
              v-model="cirugias.currency"
              placeholder="Select"
              size="medium"
            >
              <el-option
                v-for="item in currency"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="buttons-options">
          <el-button
            v-if="cirugias.id"
            type="primary"
            icon="el-icon-refresh-right"
            round
            @click="cirugiasUpdata(idx)">Actualizar
          </el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            round
            @click="cirugiasDelete(idx)">Eliminar
          </el-button>
          <el-button
            v-if="!cirugias.id"
            type="success"
            icon="el-icon-plus"
            round
            @click="cirugiasAdd(idx)"
          >
            Agregar
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    costo: {
      type: String,
      default: 'sin costo',
    },
    evaluationId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      cirugias: [],
      num: 0,
      antNumber: 0,
      ladoOptions: [
        {
          value: 'RIGHT',
          label: 'Ojo derecho',
        },
        {
          value: 'LEFT',
          label: 'Ojo izquierdo',
        },
      ],
      specialtyOptions: [
        {
          value: 'Córnea',
          label: 'Córnea',
        },
        {
          value: 'Glaucoma',
          label: 'Glaucoma',
        },
        {
          value: 'Orbita',
          label: 'Orbita',
        },
        {
          value: 'Retina',
          label: 'Retina',
        },
        {
          value: 'Oftalmopediatría',
          label: 'Oftalmopediatría',
        },
        {
          value: 'Refractiva',
          label: 'Refractiva',
        },
        {
          value: 'SegmentoAnterior',
          label: 'Segmento Anterior',
        },
      ],
      type: [
        {
          Córnea: [
            {
              value: 'TRASPLANTE DE CÓRNEA',
              label: 'TRASPLANTE DE CÓRNEA',
            },
            {
              value: 'CROSSLINKING',
              label: 'CROSSLINKING',
            },
            {
              value: 'ICL',
              label: 'ICL',
            },
            {
              value: 'ICL TÓRICO',
              label: 'ICL TÓRICO',
            },
            {
              value: 'CIERRE DE HERIDA CORNEAL',
              label: 'CIERRE DE HERIDA CORNEAL',
            },
            {
              value: 'COLGAJO CONJUNTIVAL',
              label: 'COLGAJO CONJUNTIVAL',
            },
            {
              value: 'IMPLANTE DE LENTE',
              label: 'IMPLANTE DE LENTE',
            },
            {
              value: 'LENTE FIJADO A ESCLERA',
              label: 'LENTE FIJADO A ESCLERA',
            },
          ],
          Oftalmopediatría: [
            {
              value: 'ESTRABISMO',
              label: 'ESTRABISMO',
            },
            {
              value: 'CATARATA PEDIATRICA',
              label: 'CATARATA PEDIATRICA',
            },
            {
              value: 'CHALAZION',
              label: 'CHALAZION',
            },
            {
              value: 'EXPLORACIÓN BAJO ANESTESIA',
              label: 'EXPLORACIÓN BAJO ANESTESIA',
            },
          ],
          Glaucoma: [
            {
              value: 'TRABE',
              label: 'TRABE',
            },
            {
              value: 'FACO TRABE',
              label: 'FACO TRABE',
            },
            {
              value: 'VALVULA',
              label: 'VALVULA',
            },
            {
              value: 'FACO VÁLVULA',
              label: 'FACO VÁLVULA',
            },
            {
              value: 'DIODO',
              label: 'DIODO',
            },
            {
              value: 'FACO DIODO',
              label: 'FACO DIODO',
            },
            {
              value: 'REACOMODO DE VÁLVULA',
              label: 'REACOMODO DE VÁLVULA',
            },
          ],
          Retina: [
            {
              value: 'VITRECTOMIA',
              label: 'VITRECTOMIA',
            },
            {
              value: 'FACOVITRECTOMIA',
              label: 'FACOVITRECTOMIA',
            },
            {
              value: 'FACOVITRECTOMIA + CERCLAJE',
              label: 'FACOVITRECTOMIA + CERCLAJE',
            },
            {
              value: 'VITRECTOMIA + CERCLAJE',
              label: 'VITRECTOMIA + CERCLAJE',
            },
            {
              value: 'RETIRO DE SILICÓN',
              label: 'RETIRO DE SILICÓN',
            },
            {
              value: 'FACO DIODO',
              label: 'FACO DIODO',
            },
            {
              value: 'FACO + RETIRO DE SILICÓN',
              label: 'FACO + RETIRO DE SILICÓN',
            },
          ],
          SegmentoAnterior: [
            {
              value: 'CATARATA',
              label: 'CATARATA',
            },
            {
              value: 'PTERIGIÓN',
              label: 'PTERIGIÓN',
            },
            {
              value: 'PTERIGIÓN + MEMBRANA ',
              label: 'PTERIGIÓN + MEMBRANA ',
            },
            {
              value: 'IMPLANTE DE LÍO',
              label: 'IMPLANTE DE LÍO',
            },
          ],
        },
      ],
      currency: [
        {
          label: 'USD',
          value: 'USD',
        },
        {
          label: 'MXN',
          value: 'MXN',
        },
      ],
    };
  },
  methods: {
    handleAdd(num) {
      if (num < this.antNumber) {
        this.cirugias.pop();
      } else {
        this.cirugias.push({
          number: num + 1,
          eye: null,
          specialty: null,
          type: null,
          price: 0,
        });
      }
      this.antNumber = num;
      this.num += 1;
    },
    async cirugiasUpdata(idx) {
      try {
        const carId = this.cirugias[idx].id;
        const request = await this.axios.put(`patient/surgery/${this.evaluationId}/${carId}`, this.cirugias[idx]);
        if (request) {
          this.$notify({
            type: 'success',
            title: '¡Éxito!',
            message: 'Se ha actualizado cirugía',
          });
        }
      } catch (error) {
        // // console.log(error);
      }
    },
    async getCirugias() {
      try {
        this.loading = true;
        const response = await this.axios.get(`patient/surgery/${this.evaluationId}`);
        const { data } = response;
        if (Array.isArray(data)) {
          this.cirugias = data;
          // console.log(this.cirugias);
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
    cirugiasDelete(idx) {
      try {
        if (this.cirugias[idx].id > 0) {
          this.loading = true;
          const carId = this.cirugias[idx].id;
          const request = this.axios.delete(`patient/surgery/${this.evaluationId}/${carId}`);
          if (request) {
            this.$notify({
              type: 'success',
              title: '¡Éxito!',
              message: 'Se ha eliminado cirugía',
            });
          }
          this.loading = false;
        }
        this.cirugias.splice(idx, 1);
      } catch (error) {
        this.loading = false;
        // console.log('err', error);
      }
    },
    async cirugiasAdd(idx) {
      try {
        const data = this.cirugias[idx];
        const response = await this.axios.post(`patient/newsocial/addSurgery/master/${this.evaluationId}`, data);
        if (response.status === 204) {
          this.$notify({
            type: 'success',
            message: 'Cirugía agregada con éxito',
          });
          await this.getCirugias();
        } else {
          this.$notify({
            type: 'danger',
            message: 'Error al guardar',
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
  },
  async created() {
    await this.getCirugias();
  },
};
</script>

<style lang="scss">
.add-item {
  margin-bottom: 20px;
}
.cards {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.card-general {
  min-width: 300px;
  height: auto;
  background-color: rgb(255, 255, 255);
  border: solid 1px #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  strong {
    margin-right: 10px;
    font-size: 12px;
    color: rgb(133, 133, 133);
  }
  .card-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px;
    border-bottom: 1px solid #dde0e7;
    box-sizing: border-box;
  }
}
.card-general:hover {
  border: solid 1px rgba(77, 129, 241, 0.507);;
  transition: border-color .3s cubic-bezier(.645,.045,.355,1);
  box-shadow: 0 2px 12px 0 rgba(126, 77, 241, 0.11);
  .card-title {
    border-bottom: solid 1px rgba(77, 88, 241, 0.507);;
    transition: border-color .3s cubic-bezier(.645,.045,.355,1);
  }
}
.item-card {
  padding: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  label {
    font-size: 12px;
    color: rgb(58, 58, 58);
    margin: 8px 0px;
  }
}
.buttons-options {
  display: flex;
  width: 100%;
  padding: 15px;
  flex-direction: row;
  justify-content: center;
  button {
    display: flex;
    align-items: center;
    height: 30px;
  }
}
</style>
