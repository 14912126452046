<template>
  <div class='auto-container'>
    <el-button
      type='success'
      style='align-self: flex-end;'
      @click='handleAdd'
      icon='el-icon-plus'>Agregar automóvil</el-button>
    <el-table
      :data='tableCars'
      style='width: 100%'
      stripe
      @row-click='handleRowClick'
    >
      <el-table-column prop='model' label='Marca' width='180' />
      <el-table-column prop='year' label='Modelo' />
      <el-table-column prop='plates' label='Placa' />
    </el-table>
    <el-dialog
      title='Agregar automóvil'
      width='40%'
      append-to-body
      :visible.sync='dialogFormVisible'
      :before-close='handleClose'
    >
      <span slot='title'>Agregar automóvil</span>
      <el-form
        ref='autoForm'
        :model='autoSelected'
        :rules='rules'
        class='dialog-auto'
        label-position='top'
        status-icon
      >
        <el-form-item label='Marca'>
          <el-input
            placeholder='Ingresar la marca'
            v-model='autoSelected.model'
          />
        </el-form-item>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label='Modelo'>
              <el-input
                placeholder='Ingresar el modelo'
                v-model='autoSelected.year'
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label='Placas'>
              <el-select
                placeholder='Tipo de placas'
                v-model='autoSelected.plates'>
                <el-option
                  v-for='item in placas'
                  :key='item.value'
                  :label='item.label'
                  :value='item.value'
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot='footer' class='dialog-footer'>
        <el-button @click='handleDelete'>
        Eliminar
      </el-button>
        <el-button
          @click='handleSubmit'
          :type="autoSelected.id === null ? 'success' : 'primary'"
        >
          {{ autoSelected.id === null ? 'Agregar' : 'Actualizar' }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Automoviles',
  props: {
    appointmentId: {
      type: Number,
      required: true,
    },
    evaluationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      autoSelected: {
        id: null,
        model: null,
        year: null,
        plates: null,
      },
      tableCars: [],
      placas: [
        {
          value: 'Fronterizo',
          label: 'Fronterizo',
        },
        {
          value: 'Nacional',
          label: 'Nacional',
        },
        {
          value: 'Extranjero',
          label: 'Extranjero',
        },
      ],
      rules: {
        model: [
          {
            required: true,
            message: 'Por favor ingresa el modelo',
            trigger: 'blur',
          },
        ],
        year: [
          {
            required: true,
            message: 'Por favor ingresa el año',
            trigger: 'blur',
          },
        ],
        plates: [
          {
            required: true,
            message: 'Por favor ingresa el tipo de placas',
            trigger: 'blur',
          },
        ],
      },
    };
  },

  methods: {
    handleRowClick(row) {
      this.autoSelected = {
        id: row.id,
        model: row.model,
        year: row.year,
        plates: row.plates,
      };
      this.dialogFormVisible = true;
    },
    handleClose() {
      this.dialogFormVisible = false;
    },
    handleDelete() {
      this.$confirm('¿Estás seguro de eliminar?')
        .then((_) => {
          this.deleteCar();
        })
        .catch((_) => {});
    },
    handleAdd() {
      this.autoSelected = {
        id: null,
        model: null,
        year: null,
        plates: null,
      };
      this.dialogFormVisible = true;
    },
    handleClear() {
      this.$refs.autoForm.resetFields();
    },
    async getCar() {
      try {
        const request = await this.axios.get(`patient/newsocial/car/${this.evaluationId}`);
        if (request.data) {
          const { data } = request;
          this.tableCars = data;
        }
      } catch (error) {
        this.$notify({
          title: 'Error',
          message: 'No se pudo obtener la información de los automóviles',
          type: 'error',
        });
      }
    },
    async handleSubmit() {
      try {
        if (this.autoSelected.id === null) {
          await this.handleAddCar();
        } else {
          await this.handleUpdate();
        }
      } catch (error) {
        this.$notify({
          title: 'Error',
          message: 'No se pudo agregar el automóvil',
          type: 'error',
        });
      }
    },
    async handleAddCar() {
      try {
        this.$loading();
        const request = await this.axios.post('patient/newsocial/automovil/social/new', {
          evaluationId: this.evaluationId,
          number: this.tableCars.length + 1,
          model: this.autoSelected.model,
          year: this.autoSelected.year,
          plates: this.autoSelected.plates,
        });
        if (request.data) {
          this.dialogFormVisible = false;
          this.handleClear();
          await this.getCar();
        }
      } catch (error) {
        this.$notify({
          title: 'Error',
          message: `No se pudo agregar el automóvil ${this.autoSelected.model}`,
          type: 'error',
        });
      } finally {
        this.$loading().close();
      }
    },
    async handleUpdate() {
      try {
        this.$loading();
        const request = await this.axios.put(`patient/newsocial/automovil/social/${this.autoSelected.id}`, {
          id: this.autoSelected.id,
          model: this.autoSelected.model,
          year: this.autoSelected.year,
          plates: this.autoSelected.plates,
        });
        if (request.status === 204) {
          this.$notify({
            title: 'Éxito',
            message: `Se actualizó el automóvil ${this.autoSelected.model}`,
            type: 'success',
          });
          this.dialogFormVisible = false;
          await this.getCar();
        }
      } catch (error) {
        this.$notify({
          title: 'Error',
          message: `No se pudo actualizar el automóvil ${this.autoSelected.model}`,
          type: 'error',
        });
      } finally {
        this.$loading().close();
      }
    },
    async deleteCar() {
      try {
        this.$loading();
        const request = await this.axios.delete(`patient/newSocial/automovil/social/${this.autoSelected.id}`);
        if (request.data) {
          this.dialogFormVisible = false;
          this.getCar();
        }
      } catch (error) {
        this.$notify({
          title: 'Error',
          message: `No se pudo eliminar el automóvil ${this.autoSelected.model}`,
          type: 'error',
        });
      } finally {
        this.$loading().close();
      }
    },
    prusubmit() {
      // eslint-disable-next-line consistent-return
      this.$refs.autoForm.validate((valid) => {
        if (valid) {
          const submit = this.autoSelected.id === null ? this.handleSubmit : this.handleUpdate;
          submit();
        } else {
          return false;
        }
      });
    },
  },

  mounted() {
    this.getCar();
  },
};
</script>

<style scoped>
.auto-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dialog-auto {
  padding: 10px;
}
</style>
